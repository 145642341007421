var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('guide-visual', _vm._b({}, 'guide-visual', _vm.visual, false)), _c('section', {
    staticClass: "overseas-first"
  }, [_c('scrolldown-tail'), _c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v(" 해외결연후원이 특별한 이유는 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v(" 한 아이의 삶을 변화시키는 희망의 시작입니다. ")])]), _c('v-row', _vm._l(_vm.hopes, function (hope, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 text-center mx-auto",
      attrs: {
        "max-width": "384",
        "flat": ""
      }
    }, [_c('v-card-title', {
      staticClass: "justify-center pt-40"
    }, [_c('v-img', {
      staticClass: "mb-24",
      attrs: {
        "max-width": "80",
        "src": hope.image,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "tit tit--sm primary--text",
      domProps: {
        "innerHTML": _vm._s(hope.title)
      }
    })], 1), _c('v-card-text', {
      staticClass: "page-text page-text--lg pb-40"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(hope.texts)
      }
    })])], 1)], 1);
  }), 1)], 1)], 1), _c('div', {
    staticClass: "client-content-wrap"
  }, [_c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("한 아이의 삶이 이렇게 변화됩니다")])]), _c('v-row', _vm._l(_vm.changes, function (change, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 text-center mx-auto",
      attrs: {
        "max-width": "384",
        "outlined": "",
        "color": "#ddd"
      }
    }, [_c('v-img', {
      attrs: {
        "src": change.image,
        "alt": ""
      }
    }), _c('v-card-title', {
      staticClass: "justify-center tit tit--sm primary--text pt-40 pb-24"
    }, [_vm._v(" " + _vm._s(change.title) + " ")]), _c('v-card-text', {
      staticClass: "pb-40"
    }, [_c('p', {
      staticClass: "page-text page-text--lg",
      domProps: {
        "innerHTML": _vm._s(change.texts)
      }
    })])], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("후원자와 아동의 성장 지원 프로그램")])]), _c('v-row', {
    staticClass: "process-list row--lg"
  }, _vm._l(_vm.process, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "3"
      }
    }, [_c('v-card', {
      staticClass: "process-card",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('v-row', {
      staticClass: "text-center"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('div', {
      staticClass: "primary--text font-weight-bold font-size-28"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('div', {
      staticClass: "client-title--xs font-weight-bold",
      domProps: {
        "innerHTML": _vm._s(item.name)
      }
    }), _c('div', {
      staticClass: "page-text page-text--lg grey-1--text text-center mt-8",
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    }), _c('v-img', {
      staticClass: "mt-24 mx-auto",
      attrs: {
        "src": `/images/guide/temporary/progress-${index + 1}.svg`,
        "max-width": "60"
      }
    })], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("위드캔복지재단 해외아동후원이 만들어낸 선한 영향력")]), _c('p', {
    staticClass: "section-subtitle"
  }, [_vm._v("위드캔복지재단과 함께한 후원자님들의 따뜻한 마음이 아이들의 삶을 변화시켰습니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("축구선수가 된 드숀과 엔지니어가 된 마커스의 감동적인 이야기를 확인해보세요!")])]), _c('v-row', _vm._l(_vm.influences, function (influence, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-sheet', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "494",
        "color": "transparent"
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-img', {
      attrs: {
        "src": influence.image,
        "alt": ""
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 text-center",
      attrs: {
        "color": "#FAFAFA",
        "flat": ""
      }
    }, [_c('v-card-title', {
      staticClass: "justify-center pt-lg-40 tit tit--sm primary--text"
    }, [_vm._v(" " + _vm._s(influence.title) + " ")]), _c('v-card-text', {
      staticClass: "pb-lg-40 page-text page--text--lg grey-1--text"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(influence.texts)
      }
    })])], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('section', {
    staticClass: "client-section"
  }, [_c('v-container', [_c('div', {
    staticClass: "section-title-wrap",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("후원자 참여 혜택")])]), _c('v-row', _vm._l(_vm.benefits, function (benefit, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "border-radius-16 overflow-hidden",
      attrs: {
        "outlined": "",
        "color": "#ddd"
      }
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-img', {
      attrs: {
        "width": "100%",
        "min-width": "384",
        "max-height": "768",
        "src": benefit.image,
        "aspect-ratio": 384 / 308,
        "alt": ""
      }
    })], 1), _c('v-col', [_c('div', [_c('v-card-title', {
      staticClass: "pt-0 pt-md-16 pl-md-0 pr-md-40 pb-md-24"
    }, [_c('h3', {
      staticClass: "primary--text tit tit--sm"
    }, [_vm._v(" " + _vm._s(benefit.title) + " ")])]), _c('v-card-text', {
      staticClass: "pl-md-0 pr-lg-40"
    }, [_c('v-divider', {
      staticClass: "mb-16 mb-md-24"
    }), _c('v-row', {
      staticClass: "row--sm"
    }, [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('p', {
      staticClass: "page-text page-text--lg grey-1--text",
      domProps: {
        "innerHTML": _vm._s(benefit.texts)
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      staticClass: "row--sm page-text page-text--lg"
    }, [_c('v-col', {
      staticClass: "grey-1--text font-weight-medium",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(benefit.means[0].name) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(benefit.means[0].text) + " ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1);
  }), 1)], 1)], 1)]), _c('section', [_c('v-card', {
    staticClass: "bottom-banner",
    attrs: {
      "tile": "",
      "flat": "",
      "img": "/images/guide/overseas/banner.jpg"
    }
  }, [_c('v-container', [_c('p', {
    staticClass: "page-text page-text--lg grey-1--text mb-12 mb-lg-16"
  }, [_vm._v("해외 1:1 결연 후원")]), _c('h2', {
    staticClass: "bottom-banner__title font-aggro"
  }, [_c('p', [_c('span', {
    staticClass: "primary"
  }, [_vm._v("한 아이의 삶을 바꾸는")]), _vm._v(" 당신의 선택 "), _c('br'), _vm._v(" 지금 바로 후원해주세요 ")])]), _c('v-btn', {
    staticClass: "mt-20 mt-md-40",
    attrs: {
      "href": "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
      "target": "_blank",
      "color": "white",
      "large": "",
      "rounded": "pill"
    }
  }, [_c('span', {
    staticClass: "font-size-18 font-size-md-20 font-aggro mb-n4 primary--text w-lg-160px text-center"
  }, [_vm._v("후원하기")]), _c('v-icon', {
    staticClass: "ml-10",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-chevron-right")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }