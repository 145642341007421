<template>
    <client-page>
        <guide-visual v-bind="visual" />

        <section class="overseas-first">
            <scrolldown-tail />

            <v-container>
                <div class="section-title-wrap" data-aos="fade-left">
                    <h2 class="section-title">
                        해외결연후원이 특별한 이유는 <br class="d-none d-sm-block" />
                        한 아이의 삶을 변화시키는 희망의 시작입니다.
                    </h2>
                </div>

                <v-row>
                    <v-col v-for="(hope, index) in hopes" :key="index" cols="12" lg="4">
                        <v-card max-width="384" flat class="border-radius-16 text-center mx-auto">
                            <v-card-title class="justify-center pt-40">
                                <v-img max-width="80" :src="hope.image" alt="" class="mb-24" />
                                <div v-html="hope.title" class="tit tit--sm primary--text" />
                            </v-card-title>
                            <v-card-text class="page-text page-text--lg pb-40">
                                <p v-html="hope.texts" />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </section>

        <div class="client-content-wrap">
            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">한 아이의 삶이 이렇게 변화됩니다</h2>
                    </div>

                    <v-row>
                        <v-col v-for="(change, index) in changes" :key="index" cols="12" lg="4">
                            <v-card max-width="384" outlined color="#ddd" class="border-radius-16 text-center mx-auto">
                                <v-img :src="change.image" alt="" />
                                <v-card-title class="justify-center tit tit--sm primary--text pt-40 pb-24">
                                    {{ change.title }}
                                </v-card-title>
                                <v-card-text class="pb-40">
                                    <p v-html="change.texts" class="page-text page-text--lg" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">후원자와 아동의 성장 지원 프로그램</h2>
                    </div>

                    <v-row class="process-list row--lg">
                        <v-col v-for="(item, index) in process" :key="index" cols="12" lg="3">
                            <v-card color="#FAFAFA" flat class="process-card">
                                <v-row class="text-center">
                                    <v-col cols="12">
                                        <div class="primary--text font-weight-bold font-size-28">0{{ index + 1 }}</div>
                                        <div v-html="item.name" class="client-title--xs font-weight-bold" />
                                        <div v-html="item.text" class="page-text page-text--lg grey-1--text text-center mt-8" />
                                        <v-img :src="`/images/guide/temporary/progress-${index + 1}.svg`" max-width="60" class="mt-24 mx-auto" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">위드캔복지재단 해외아동후원이 만들어낸 선한 영향력</h2>
                        <p class="section-subtitle">위드캔복지재단과 함께한 후원자님들의 따뜻한 마음이 아이들의 삶을 변화시켰습니다. <br class="d-none d-md-block" />축구선수가 된 드숀과 엔지니어가 된 마커스의 감동적인 이야기를 확인해보세요!</p>
                    </div>

                    <v-row>
                        <v-col v-for="(influence, index) in influences" :key="index" cols="12" lg="6">
                            <v-sheet max-width="494" color="transparent" class="mx-auto">
                                <v-row>
                                    <v-col cols="12">
                                        <v-img :src="influence.image" alt="" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-card color="#FAFAFA" flat class="border-radius-16 text-center">
                                            <v-card-title class="justify-center pt-lg-40 tit tit--sm primary--text">
                                                {{ influence.title }}
                                            </v-card-title>
                                            <v-card-text class="pb-lg-40 page-text page--text--lg grey-1--text">
                                                <p v-html="influence.texts" />
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-container>
            </section>

            <section class="client-section">
                <v-container>
                    <div class="section-title-wrap" data-aos="fade-left">
                        <h2 class="section-title">후원자 참여 혜택</h2>
                    </div>

                    <v-row>
                        <v-col v-for="(benefit, index) in benefits" :key="index" cols="12">
                            <v-card outlined color="#ddd" class="border-radius-16 overflow-hidden">
                                <v-row align="center" class="row--lg">
                                    <v-col cols="12" md="auto">
                                        <v-img width="100%" min-width="384" max-height="768" :src="benefit.image" :aspect-ratio="384 / 308" alt="" />
                                    </v-col>
                                    <v-col>
                                        <div>
                                            <v-card-title class="pt-0 pt-md-16 pl-md-0 pr-md-40 pb-md-24">
                                                <h3 class="primary--text tit tit--sm">
                                                    {{ benefit.title }}
                                                </h3>
                                            </v-card-title>
                                            <v-card-text class="pl-md-0 pr-lg-40">
                                                <v-divider class="mb-16 mb-md-24" />
                                                <v-row class="row--sm">
                                                    <v-col cols="12">
                                                        <p v-html="benefit.texts" class="page-text page-text--lg grey-1--text" />
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row class="row--sm page-text page-text--lg">
                                                            <v-col cols="auto" class="grey-1--text font-weight-medium">
                                                                {{ benefit.means[0].name }}
                                                            </v-col>
                                                            <v-col>
                                                                {{ benefit.means[0].text }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </section>
        </div>

        <section>
            <v-card tile flat img="/images/guide/overseas/banner.jpg" class="bottom-banner">
                <v-container>
                    <p class="page-text page-text--lg grey-1--text mb-12 mb-lg-16">해외 1:1 결연 후원</p>
                    <h2 class="bottom-banner__title font-aggro">
                        <p>
                            <span class="primary">한 아이의 삶을 바꾸는</span> 당신의 선택 <br />
                            지금 바로 후원해주세요
                        </p>
                    </h2>
                    <v-btn href="https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w" target="_blank" color="white" large rounded="pill" class="mt-20 mt-md-40">
                        <span class="font-size-18 font-size-md-20 font-aggro mb-n4 primary--text w-lg-160px text-center">후원하기</span>
                        <v-icon small class="ml-10">mdi-chevron-right</v-icon>
                    </v-btn>
                </v-container>
            </v-card>
        </section>
    </client-page>
</template>

<script>
import GuideVisual from "@/components/client/guide/guide-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import ScrolldownTail from "@/components/dumb/scrolldown-tail.vue";

export default {
    components: {
        ClientPage,
        GuideVisual,
        ScrolldownTail,
    },
    data() {
        return {
            visual: {
                image: "/images/guide/overseas/visual.jpg",
                title: "해외아동결연",
                texts: "당신의 소중한 선택, <br />한 아이의 꿈을 함께 키웁니다.",
                button: {
                    text: "해외아동 1:1 결연하기",
                    link: "https://secure.donus.org/withcan/pay/step1?_gl=1*1csn9b9*_ga*MTA5ODMyNDAzLjE3MjE4MTAwODU.*_ga_1KFQVNYJMP*MTc0Mjk2Mzc5OS4yMi4xLjE3NDI5NjU5ODYuNTkuMC4w",
                },
                white: true,
            },

            hopes: [
                {
                    image: "/images/guide/overseas/hope-1.svg",
                    title: "아동을 최우선으로 <br />생각하는 지원",
                    texts: "모든 아동을 차별 없이 지원하며, <br />아동의 권리와 목소리를 존중합니다",
                },
                {
                    image: "/images/guide/overseas/hope-2.svg",
                    title: "마을 전체의 <br />변화를 만드는 접근",
                    texts: "아동이 살아가는 환경의 근본적인 문제를 <br />해결하고 지역사회의 자립을 돕습니다",
                },
                {
                    image: "/images/guide/overseas/hope-3.svg",
                    title: "후원자와 아동의 <br />지속적인 연결과 소통",
                    texts: "후원자는 아동과 지역의 변화를 주기적으로 <br />확인하며 성장의 과정을 함께 경험합니다",
                },
            ],
            changes: [
                {
                    image: "/images/guide/overseas/change-1.jpg",
                    title: "인도적지원영역",
                    texts: "아동의 건강한 성장을 위해 의식주에 필요한 <br />기초 생필품 및 식량을 제공하여, <br />생명과 건강을 보호합니다",
                },
                {
                    image: "/images/guide/overseas/change-2.jpg",
                    title: "교육영역",
                    texts: "아동이 꿈을 포기하지 않고 지속적으로 <br />꿈을 키워갈 수 있도록 학비·교과서·학용품 등의 <br />교육서비스를 지원합니다",
                },
                {
                    image: "/images/guide/overseas/change-3.jpg",
                    title: "의료영역",
                    texts: "예방접종·영양제 등으로 아동의 기초영양과 <br />보건을 챙기고, 아동이 다치거나 질병 발생 시 <br />치료 및 긴급 의료지원을 제공합니다",
                },
            ],
            process: [
                {
                    name: "해외아동 결연 신청",
                    text: "신청일",
                },
                {
                    name: "아동 소개카드 우편발송",
                    text: "3주이내",
                },
                {
                    name: "아동 성장발달 보고서 및 아동편지 발송",
                    text: "연 1회",
                },
                {
                    name: "감사와 작별의 편지",
                    text: "결연아동 종결",
                },
            ],
            influences: [
                {
                    image: "/images/guide/overseas/influence-1.png",
                    title: "드숀의 이야기",
                    texts: "쓰레기마을에서 꿈도 없이 살아가던 저는 <br />후원자님의 도움으로 고등학교에 진학하게 되었어요. <br />학교에서 축구를 하는 이 순간이 꿈만 같아요.",
                },
                {
                    image: "/images/guide/overseas/influence-2.png",
                    title: "마커스의 이야기",
                    texts: "어려운 환경에서도 엔지니어의 꿈을 포기하지 않았던 마커스. <br />위드캔복지재단의 지원으로 교육을 마치고, <br />지금은 자신의 기술로 지역사회를 변화시키고 있습니다.",
                },
            ],
            benefits: [
                {
                    image: "/images/guide/overseas/benefit-1.jpg",
                    title: "온정 나누기",
                    texts: "<strong>연 1회 선물 보내기 캠페인</strong>에 참여하시면, 소중한 후원금으로 현지에서 아동들에게 <br class='d-none d-xl-block' />마음을 담은 선물을 전달하게 됩니다. 개별 선물 대신, 모든 아동에게 동일한 선물을 통해 공평하고 <br class='d-none d-xl-block'/>따뜻한 나눔을 실천할 수 있습니다. 이는 선물 분실을 예방하고, 아동들 간의 배려와 함께하는 마음을 <br class='d-none d-xl-block'/>키울 수 있도록 돕는 방법입니다. 여러분의 사랑이 아동들에게 고르게 전달될 수 있도록 함께해주세요.",
                    means: [
                        {
                            name: "후원 계좌",
                            text: "국민은행 027001-04-234141 (예금주) 사회복지법인 위드캔복지재단",
                        },
                    ],
                },
                {
                    image: "/images/guide/overseas/benefit-2.jpg",
                    title: "온기 전하기",
                    texts: "<strong>당신의 이야기가 결연아동에게 희망이 됩니다.</strong><br />후원자님의 따스한 마음이 담긴 편지를 작성해 위드캔복지재단으로 보내주세요. 모든 편지는 번역 후 <br class='d-none d-xl-block' />1월, 5월, 9월에 현지로 전달됩니다. 아이들에게 전해질 한 통의 편지가 그들의 세상을 환히 비출 수 있습니다.",
                    means: [
                        {
                            name: "보내실 곳",
                            text: "(우) 02751 서울특별시 성북구 화랑로11길 26 갑을명가 2층 215",
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.overseas-first {
    position: relative;
    background-color: #fafafa;
    padding: 100px 0;
}
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
            word-break: keep-all;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.bottom-banner {
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    padding: 20px 0;
    &__title {
        font-size: 28px;
        font-weight: 500;
        .point {
            font-size: 50px;
        }
        .primary {
            padding-top: 4px;
            color: #fff;
        }
    }
}
@media (min-width: 768px) {
    .overseas-first {
        padding: 160px 0;
    }
    .bottom-banner {
        aspect-ratio: 1920/440;
        display: flex;
        align-items: center;
        &__title {
            font-size: 36px;
            .point {
                font-size: 70px;
            }
            .primary {
                padding-top: 8px;
            }
        }
    }
}
@media (min-width: 1024px) {
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
}
</style>